<template>
   <div class="copy-right border-top">
    <ul class="footer1 d-md-flex justify-content-center my-2 gap-2" v-if="($auth &&
      $auth.setting &&
      $auth.setting.show_contact_developer &&
      ($auth.setting.show_contact_developer === 'Yes' || $auth.setting.show_contact_developer === 'No'))">
      <li>
        <a target="_blank" class="footer-text-color" href="https://dashboard.chownowapp.com/vendor/NDA4MTg=/signup">Become a Restaurant Partner | </a>
      </li>
      <li><a target="_blank" class="footer-text-color" href="https://dashboard.chownowapp.com/drivers/NDA4MTg=/signup">Become Driver Partner</a></li>
    </ul>
    
    <!-- <cookie-law theme="dark-lime">
      <div slot="message">This {{$auth.getAppName()}} uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance</div>
    </cookie-law> -->
  </div>
  <!-- <div class="copy-right border-top">
    <p v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'No' ">      
      <span>Powered by <u><a target="_blank" class="primary-link" href="https://deonde.co/">DEONDE</a></u></span>
    </p>
    <cookie-law theme="dark-lime">
      <div slot="message">This {{$auth.getAppName()}} uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance</div>
    </cookie-law>
  </div>     -->
</template>

<script>
import CookieLaw  from 'vue-cookie-law';
export default {
  name:'footerpage',
  components: { CookieLaw }
}
</script>