<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="add-address-main box-flex">
      <div class="container review-main text-capitalize position-relative box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card">
              <div class="center-card-box | p-3 border-bottom top-sticky">
                <div class="del-address w-100">
                  <h4 class="mb-2">{{ $t('Update Address') }}</h4>
                  <vue-google-autocomplete v-if="$auth.google_map_key" id="mapdata" class="form-control" type="text"
                    v-model="typeform.address" v-on:placechanged="getAddressData" :placeholder="$t('Search Location')"
                    :country="$auth.setting.country_code_type"
                    :types="['address', 'locality', 'sublocality']"></vue-google-autocomplete>
                </div>
              </div>
              <div class="center-card-box">
                <div class="responsive-map">
                  <!-- <GmapMap v-if="$auth.google_map_key" :center="center" :zoom="18" map-style-id="roadmap" :options="mapOptions" style="width: 100%; height: 300px" ref="mapRef" @click="handleMapClick" >
                    <GmapMarker :position="marker.position" :clickable="true" :draggable="true" @drag="handleMarkerDrag" @click="panToMarker" />
                  </GmapMap> -->
                  <div v-if="!isMapShow" class="text-center col-md-12 p-0 my-">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <GoogleMaps v-else :markers="marker.position" :center="center" page="addaddress" :options="mapOptions"
                    v-on:onHandleClick="handleMapClick"></GoogleMaps>
                </div>
              </div>
              <div class="center-card-box | p-3">
                <div class="review-boxs mt-4">
                  <div class="mt-4 position-relative">
                    <h6 class="mb-2">{{ $t('House/Building Name') }}</h6>
                    <input class="form-control mb-0 h50" type="text" v-model="$v.typeform.house_name.$model"
                      :placeholder="$t('Enter House/Building Name')" aria-label="House/Building Name"
                      :class="{ 'is-invalid': $v.typeform.house_name.$error }" />
                    <div v-if="$v.typeform.house_name.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.house_name.required">{{ $t('Enter House/Building Name') }}.</span>
                    </div>
                  </div>
                  <div class="mt-4 position-relative">
                    <h6 class="mb-2">{{ $t('Flat Number/Floor') }}</h6>
                    <input class="form-control mb-0 h50" type="text" v-model="$v.typeform.flat_no.$model"
                      :placeholder="$t('Enter Flat/ Floor Number')" aria-label="Flat Number/ Floor"
                      :class="{ 'is-invalid': $v.typeform.flat_no.$error }" />
                    <div v-if="$v.typeform.flat_no.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.flat_no.required">{{ $t('Enter Flat/ Floor Number') }}</span>
                      <span v-else-if="!$v.typeform.flat_no.numeric">{{ $t('This value should be a number') }}</span>
                    </div>
                  </div>
                  <div class="mt-4 position-relative">
                    <h6 class="mb-2">{{ $t('Landmark') }}</h6>
                    <input class="form-control mb-0 h50" type="text" v-model="typeform.landmark"
                      :placeholder="$t('Enter Landmark')" aria-label="Landmark" />
                  </div>
                </div>
                <div class="mt-4 mb-4">
                  <h6 class="mb-3">{{ $t('Set tag for Address') }}</h6>
                  <form class="boxed d-flex">
                    <input type="radio" id="home" name="Home" value="Home" v-model="typeform.address_clarification" />
                    <label for="home">{{ $t('Home') }}</label>

                    <input type="radio" id="office" name="office" value="Office"
                      v-model="typeform.address_clarification" />
                    <label for="office">{{ $t('Office') }}</label>

                    <input type="radio" id="other" name="other" value="Other" v-model="typeform.address_clarification" />
                    <label for="other">{{ $t('Other') }}</label>
                  </form>
                </div>
              </div>
              <div class="center-card-box | bottom-sticky">
                <div class="full-btn">
                  <b-button variant="primary" disabled v-if="isDisable"><b-spinner small type="grow"></b-spinner>{{
                    $t("Loading") }}...</b-button>
                  <button v-else @click="geoAddress">{{ $t('Update Address') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import NavBar from "../components/NavBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Footer from '../components/Footer.vue';
import GoogleMaps from "../components/GoogleMaps.vue";

export default {
  name: "updateaddress",
  components: {
    NavBar,
    VueGoogleAutocomplete,
    Footer,
    GoogleMaps
  },
  data() {
    return {
      googleLoaded: false,
      marker: { position: { lat: 23, lng: 72 } },
      center: { lat: 23, lng: 72 },
      scaleModal: false,
      mapOptions: {
        disableDefaultUI: true,
        zoom: 15,
      },
      select_address: "",
      typeform: {
        city: "",
        address_clarification: "Home",
        landmark: "",
        address: "",
        is_langauge: "",
        state: "",
        vendor_id: this.$auth.getVendorId(),
        flat_no: "",
        house_name: "",
        latitude: "",
        zip_code: "",
        longitude: "",
        shiping_id: this.$route.params.shiping_id
      },
      isMapShow: false,
      isDisable: true
    };
  },
  validations: {
    typeform: {
      address: {
        required,
      },
      house_name: {},
      flat_no: {
        numeric
      }
    },
  },
  computed: {
    ...mapGetters("address", ["addressList"]),
  },
  mounted() {
    this.AddressesList();
    this.geolocate();
  },
  methods: {
    ...mapActions("address", ["newAddress", "updateNewAddress", "getAddress"]),
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        if (this.typeform.latitude == '' && this.typeform.longitude == '') {
          this.marker.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        } else {
          this.marker.position = {
            lat: parseFloat(this.typeform.latitude),
            lng: parseFloat(this.typeform.longitude),
          };
        }
        this.center.lat = this.marker.position.lat;
        this.center.lng = this.marker.position.lng;
        if (this.typeform.latitude == '' && this.typeform.longitude == '') {
          this.locateAddress(position.coords.latitude, position.coords.longitude);
        } else {
          this.locateAddress(this.typeform.latitude, this.typeform.longitude);
        }
        this.isDisable = false;
        this.isMapShow = true;
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.locateAddress(e.latLng.lat(), e.latLng.lng());
    },

    locateAddress(lat, lng) {

      if (!this.$auth.google_map_key) {
        return false;
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          this.typeform.address = results[0].formatted_address;
          let addd = results[0].address_components;
          for (const component of addd) {
            const componentType = component.types[0];
            switch (componentType) {
              case "postal_code": {
                this.typeform.zip_code = component.long_name;
                break;
              }

              case "locality":
                this.typeform.city = component.long_name
                break;

              case "administrative_area_level_1": {
                this.typeform.state = component.short_name;
                break;
              }
            }
          }
          // var value = this.typeform.address.split(", ");
          // var value1 = this.typeform.address.split(" ");
          // var count = value.length;
          // var count1 = value1.length;
          // this.typeform.state = value1[count1 - 3];
          // this.typeform.city = value[count - 3];
          // this.typeform.zip_code = value1[count1 - 2];
          this.typeform.latitude = this.marker.position.lat;
          this.typeform.longitude = this.marker.position.lng;
        }
      });
    },

    //Moves the map view port to marker
    // panToMarker() {
    //   this.$refs.mapRef.panTo(this.marker.position);
    // },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.locateAddress(e.latLng.lat(), e.latLng.lng());
    },

    getAddressData(addressData, placeResultData) {
      this.typeform.address = placeResultData.formatted_address;
      this.typeform.latitude = addressData.latitude;
      this.typeform.longitude = addressData.longitude;
      this.marker.position = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      // this.$refs.mapRef.panTo(this.marker.position);
      this.$bvModal.hide('scaleModal');
      this.locateAddress(addressData.latitude, addressData.longitude);
    },

    geoAddress() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("city", this.typeform.city);
        fd.append("address_clarification", this.typeform.address_clarification);
        fd.append("landmark", this.typeform.landmark);
        fd.append("address", this.typeform.address);
        fd.append("is_langauge", this.typeform.is_langauge);
        fd.append("state", this.typeform.state);
        fd.append("vendor_id", this.typeform.vendor_id);
        fd.append("flat_no", this.typeform.flat_no);
        fd.append("house_name", this.typeform.house_name);
        fd.append("latitude", this.typeform.latitude);
        fd.append("zip_code", this.typeform.zip_code);
        fd.append("longitude", this.typeform.longitude);
        fd.append("shiping_id", this.typeform.shiping_id);
        this.updateNewAddress(fd).then((data) => {
          if (data.code == 200) {
            this.$toasted.success(this.$t("Address updated successfully"), {
              duration: 2000,
            });
            this.$router.push({ name: "address" });
          }
        });
      }
    },

    AddressesList() {
      this.getAddress({
        devicetoken: "",
        devicetype: "",
        uniqueid: "",
        type: "",
        is_langauge: this.$store.state.lang.locale
      }).then((data) => {
        if (data.code == 200) {
          this.select_address = data.Result.filter(x => x.shiping_id == this.$route.params.shiping_id);
          if (this.select_address.length > 0) {
            this.typeform = {
              city: this.select_address[0].city,
              address_clarification: this.select_address[0].adress_clarification,
              landmark: this.select_address[0].landmark,
              address: this.select_address[0].Shiping_address,
              is_langauge: "",
              state: this.select_address[0].state,
              vendor_id: this.$auth.getVendorId(),
              flat_no: this.select_address[0].flat_no,
              house_name: this.select_address[0].house_name,
              latitude: this.select_address[0].latitude,
              zip_code: this.select_address[0].zip_code,
              longitude: this.select_address[0].longitude,
              shiping_id: this.select_address[0].shiping_id
            }
          }
        }
      });
    },
  },
};
</script>